import { api } from '@teamflow/client-api'
import { result, retryWithTimeout } from '@teamflow/lib'
import {
    ApiError,
    JoinAccessProps,
    JoinGuestProps,
    JoinGuestResponse,
} from '@teamflow/types'

export async function putGuestUser(body: JoinAccessProps & JoinGuestProps) {
    return result<JoinGuestResponse, ApiError>(
        retryWithTimeout(
            () =>
                new Promise((resolve, reject) => {
                    void api.join.asNewGuest(body).then(({ data, error }) => {
                        if (!data) {
                            reject(error)
                            return
                        }
                        resolve(data)
                    })
                }),
            3,
            5000
        )
    )
}
