import { useTranslation } from 'react-i18next'

import { Button, ItemWithMessage } from '@teamflow/design'
import allTKeys from '@teamflow/translations'

const translationPage = 'signup'
const tKeys = allTKeys.signup

export const AVSetupJoinButton = ({
    isGeneric,
    isGuest,
    isDisabled,
    showError,
    errorMessage,
    dataTestId,
    onJoinClick,
    isLoading,
}: {
    isGeneric: boolean
    isGuest: boolean
    isDisabled: boolean
    showError: boolean
    errorMessage?: string
    dataTestId?: string
    onJoinClick: () => void
    isLoading: boolean
}) => {
    const { t } = useTranslation(translationPage)
    return (
        <ItemWithMessage
            level="danger"
            light
            message={errorMessage}
            data-testid={dataTestId}
            show={showError}
        >
            <Button
                data-testid={
                    isGeneric
                        ? 'av-setup-join'
                        : isGuest
                        ? 'guest-submit'
                        : 'signup-submit-user'
                }
                size="lg"
                disabled={isDisabled}
                isFullWidth
                onClick={onJoinClick}
                isLoading={isLoading}
            >
                {isGeneric || isGuest
                    ? t(tKeys.guest.join)
                    : t(tKeys.user.continue)}
            </Button>
        </ItemWithMessage>
    )
}
