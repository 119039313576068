import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Column, Input, ItemWithMessage } from '@teamflow/design'
import allTKeys from '@teamflow/translations'

import { MAX_USER_NAME_LEN } from '../../../../constants'

const translationPage = 'signup'
const guestKeys = allTKeys.signup.guest
const userKeys = allTKeys.signup.user

const flexDirection = {
    mobile: 'column',
    desktop: 'row',
} as const

export const GuestSignupDetails = ({
    userName,
    userEmail,
    onUserNameChange,
    onUserEmailChange,
    userEmailInvalid,
}: {
    userName: string
    userEmail: string
    onUserNameChange: (value: string) => void
    onUserEmailChange: (value: string) => void
    userEmailInvalid: boolean
}) => {
    const { t } = useTranslation(translationPage)
    return (
        <Box display="flex" gap="space24" flexDirection={flexDirection}>
            <Column>
                <Input
                    label={t(guestKeys.yourName)}
                    onChange={useCallback(
                        (event) => onUserNameChange(event.target.value),
                        [onUserNameChange]
                    )}
                    type="text"
                    id="name"
                    isRequired
                    data-testid="guest-name"
                    width="fill"
                    defaultValue={userName}
                    maxLength={MAX_USER_NAME_LEN}
                />
            </Column>
            <Column>
                <ItemWithMessage
                    level="danger"
                    message={t(userKeys.pleaseEnterAValidEmail)}
                    show={userEmailInvalid}
                    width="fill"
                    data-testid="tf.signup.guest-form.invalid-email-error-message"
                >
                    <Input
                        data-testid="guest-email"
                        label={`${t(guestKeys.yourEmail)} ${t(
                            guestKeys.optional
                        )}`}
                        onChange={useCallback(
                            (event) => onUserEmailChange(event.target.value),
                            [onUserEmailChange]
                        )}
                        type="email"
                        id="email"
                        width="fill"
                        defaultValue={userEmail}
                    />
                </ItemWithMessage>
            </Column>
        </Box>
    )
}
