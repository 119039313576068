import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Column,
    Columns,
    Input,
    ItemWithMessage,
    Stack,
} from '@teamflow/design'
import { PasswordStrength } from '@teamflow/lib'
import allTKeys from '@teamflow/translations'

import {
    MAX_FIRST_NAME_LEN,
    MAX_LAST_NAME_LEN,
    MIN_PASS_LEN,
} from '../../../../constants'

import GoogleButton from '../../../common/GoogleButton'
import { MenuDivider } from '../../../common/MenuDivider'
import PasswordButton from '../../../common/PasswordButton'
import { PhoneNumberInput } from '../../../common/PhoneNumberInput'

import { PasswordStrengthIndicator } from './PasswordStrength'

const translationPage = 'signup'
const userKeys = allTKeys.signup.user

export const UserSignupDetails = ({
    userFirstName,
    userLastName,
    userEmail,
    requestPhoneNumber,
    passwordMinCharacters,
    passwordStrength,
    userEmailTaken,
    userEmailInvalid,
    userEmailGeneric,
    userPhone,
    userPhoneInvalid,
    userPhoneRequired,
    onUserFirstNameChange,
    onUserLastNameChange,
    onUserEmailChange,
    onUserPhoneChange,
    onUserPasswordChange,
    onGoogleLoginClick,
}: {
    userFirstName: string
    userLastName: string
    userEmail: string
    requestPhoneNumber: boolean
    passwordMinCharacters: boolean
    passwordStrength: PasswordStrength
    userEmailTaken: boolean
    userEmailInvalid: boolean
    userEmailGeneric: boolean
    userPhone: string
    userPhoneInvalid: boolean
    userPhoneRequired: boolean
    onUserFirstNameChange: (value: string) => void
    onUserLastNameChange: (value: string) => void
    onUserEmailChange: (value: string) => void
    onUserPhoneChange: (value: string) => void
    onUserPasswordChange: (value: string) => void
    onGoogleLoginClick: () => void
}) => {
    const { t } = useTranslation(translationPage)

    const [showPassword, setShowPassword] = useState(false)

    return (
        <>
            <GoogleButton onClick={onGoogleLoginClick}>
                {t(userKeys.continueWithGoogle)}
            </GoogleButton>
            <MenuDivider />
            <Stack space="space16">
                <Columns space="space24">
                    <Column>
                        <Input
                            label={t(userKeys.firstName)}
                            onChange={useCallback(
                                (event) =>
                                    onUserFirstNameChange(event.target.value),
                                [onUserFirstNameChange]
                            )}
                            type="text"
                            id="firstName"
                            isRequired
                            data-testid="signup-first-name"
                            width="fill"
                            defaultValue={userFirstName}
                            maxLength={MAX_FIRST_NAME_LEN}
                        />
                    </Column>
                    <Column>
                        <Input
                            label={t(userKeys.lastName)}
                            onChange={useCallback(
                                (event) =>
                                    onUserLastNameChange(event.target.value),
                                [onUserLastNameChange]
                            )}
                            type="text"
                            id="lastName"
                            isRequired
                            data-testid="signup-last-name"
                            width="fill"
                            defaultValue={userLastName}
                            maxLength={MAX_LAST_NAME_LEN}
                        />
                    </Column>
                </Columns>

                <Columns space="space24">
                    <Column>
                        <ItemWithMessage
                            level="danger"
                            message={
                                userEmailTaken
                                    ? t(
                                          userKeys.anAccountAlreadyExistsWithThisEmailDidYouMeanToLogIn
                                      )
                                    : userEmailInvalid
                                    ? t(userKeys.pleaseEnterAValidEmail)
                                    : t(userKeys.pleaseUseWorkEmail)
                            }
                            show={
                                userEmailTaken ||
                                userEmailInvalid ||
                                userEmailGeneric
                            }
                            width="fill"
                            data-testid="tf.signup.user-form.invalid-email-error-message"
                        >
                            <Input
                                label={t(userKeys.workEmail)}
                                onChange={useCallback(
                                    (event) =>
                                        onUserEmailChange(event.target.value),
                                    [onUserEmailChange]
                                )}
                                type="email"
                                id="email"
                                isRequired
                                isInvalid={userEmailInvalid || userEmailTaken}
                                data-testid="signup-email"
                                defaultValue={userEmail}
                            />
                        </ItemWithMessage>
                    </Column>
                    {requestPhoneNumber && (
                        <Column>
                            <ItemWithMessage
                                level="danger"
                                message={t(
                                    userKeys.pleaseEnterAValidPhoneNumber
                                )}
                                show={userPhoneInvalid}
                                width="fill"
                            >
                                <PhoneNumberInput
                                    value={userPhone}
                                    onChange={onUserPhoneChange}
                                    label={t(userKeys.phone)}
                                    required={userPhoneRequired}
                                />
                            </ItemWithMessage>
                        </Column>
                    )}
                </Columns>
                <Columns space="space24">
                    <Column>
                        <ItemWithMessage
                            data-testid="tf.signup.user-form.passwords-min-chars"
                            level="danger"
                            message={t(userKeys.minCharacters, {
                                amount: MIN_PASS_LEN,
                            })}
                            show={passwordMinCharacters}
                            width="fill"
                        >
                            <Box position="absolute" right="none" top="space2">
                                <PasswordStrengthIndicator
                                    strength={passwordStrength}
                                />
                            </Box>
                            <Input
                                label={t(userKeys.password)}
                                min={MIN_PASS_LEN}
                                onChange={useCallback(
                                    (event) =>
                                        onUserPasswordChange(
                                            event.target.value
                                        ),
                                    [onUserPasswordChange]
                                )}
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                isRequired
                                isInvalid={passwordMinCharacters}
                                width="fill"
                                data-testid="signup-password"
                                aria-describedby="password-helper-text"
                                placeholder={t(userKeys.minCharacters, {
                                    amount: MIN_PASS_LEN,
                                })}
                                autoCapitalize="off"
                                autoCorrect="off"
                                after={
                                    <PasswordButton
                                        showPassword={showPassword}
                                        setShowPassword={setShowPassword}
                                    />
                                }
                            />
                        </ItemWithMessage>
                    </Column>
                </Columns>
            </Stack>
        </>
    )
}
