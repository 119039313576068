import * as Sentry from '@sentry/react'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DeniedScreenProps } from '@teamflow/bootstrap'
import { Button, Text } from '@teamflow/design'
import { LogManager } from '@teamflow/lib'
import allTKeys from '@teamflow/translations'
import { ApiErrorCode } from '@teamflow/types'
import { useRejectFlow, Flow, Events } from '@teamflow/web/src/hooks/userFlow'

import { LoadingFunnel, track } from '../../helpers/analytics'

import { CenterContent } from '../common'
import HuddleHeader from '../common/HuddleHeader'
import HuddleHeading from '../common/HuddleHeading'

const translationPage = 'signup'
const tKeys = allTKeys.signup.denied

export default function DeniedScreen({ error }: DeniedScreenProps) {
    const { t } = useTranslation(translationPage)
    const router = useRouter()

    useRejectFlow(Flow.Login, Events.Denied)

    useEffect(() => {
        track(LoadingFunnel.HitAccessDenied)
    }, [])

    let title = tKeys.accessMissing
    let details =
        tKeys.looksLikeYouDontHaveAccessToThisOrganizationOrHadAnInvalidLink

    switch (error) {
        case String(ApiErrorCode.UnknownOrganization):
            title = tKeys.workspaceNotFound
            details = tKeys.itSeemsLikeYouDoNotHaveTheRightLink
            break
        default:
            if (error) details = tKeys.oops
            LogManager.ui.error('Missing Access Error', error)
            Sentry.captureException(error, {
                tags: {
                    is_signup: true,
                    missing_access: true,
                },
            })
            break
    }

    const handleLogin = useCallback(() => {
        return router.push('/login')
    }, [router])

    return (
        <>
            <HuddleHeader />
            <CenterContent>
                <HuddleHeading marginBottom="space24">{t(title)}</HuddleHeading>
                <Text size="textSize14" color="neutral70">
                    {t(details)}
                </Text>
                <Button
                    data-testid="access-denied-login"
                    size="lg"
                    isFullWidth
                    marginTop="space32"
                    onClick={handleLogin}
                >
                    {t(tKeys.logIn)}
                </Button>
            </CenterContent>
        </>
    )
}
