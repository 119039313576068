import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import allTKeys from '@teamflow/translations'

import { apostrophizeName } from '../../../../helpers/apostrophizeName'

import HuddleHeading from '../../../common/HuddleHeading'

const translationPage = 'signup'
const avSetupKeys = allTKeys.signup.avSetup
const guestKeys = allTKeys.signup.guest
const userKeys = allTKeys.signup.user

export const AVSetupHeading = ({
    isGeneric,
    isGuest,
    orgName,
    inviteCode,
    isSalesfloor = false,
}: {
    isGeneric?: boolean
    isGuest?: boolean
    orgName?: string | null
    inviteCode?: string
    isSalesfloor?: boolean
}) => {
    const { t, i18n } = useTranslation(translationPage)

    const title = useMemo(() => {
        if (isSalesfloor && inviteCode) {
            return t(avSetupKeys.signupToJoinYourSalesFloor)
        }

        if (isSalesfloor) {
            return t(avSetupKeys.signupCreateSalesFloor)
        }

        if (isGeneric) {
            return t(avSetupKeys.setUpYourAudioVideo)
        }

        if (isGuest) {
            return t(guestKeys.visitOrgDisplayNameSOffice, {
                orgDisplayName: apostrophizeName(
                    orgName ?? 'The',
                    i18n.language
                ),
            })
        }

        if (orgName) {
            return t(userKeys.youAreInvitedToJoinOrgNamesOffice, {
                orgName,
            })
        }

        return t(userKeys.signUpToYourOffice, {
            action: inviteCode ? t(userKeys.join) : t(userKeys.create),
        })
    }, [
        t,
        isSalesfloor,
        isGeneric,
        isGuest,
        orgName,
        inviteCode,
        i18n.language,
    ])

    return <HuddleHeading marginBottom="space16">{title}</HuddleHeading>
}
