import { useTranslation } from 'next-i18next'

import { Color, Text } from '@teamflow/design'
import { PasswordStrength } from '@teamflow/lib'
import allTKeys from '@teamflow/translations'

const translationPage = 'signup'
const tKeys = allTKeys.signup.password

const color: Color[] = ['danger', 'danger', 'info', 'success', 'success']
const tKey = ['veryWeak', 'weak', 'moderate', 'strong', 'veryStrong'] as const

export const PasswordStrengthIndicator = ({
    strength,
}: {
    strength: PasswordStrength
}) => {
    const { t } = useTranslation(translationPage)
    return strength > -1 ? (
        <Text size="textSize12" color={color[strength]} weight="400">
            {t(tKeys[tKey[strength]])}
        </Text>
    ) : null
}
