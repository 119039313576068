import React from 'react'

import { Box, BoxProps } from '@teamflow/design'
import HuddleHeader from '@teamflow/web/src/components/common/HuddleHeader'
import Root from '@teamflow/web/src/components/common/Root'

import type { PropsWithChildren } from 'react'

export default function Page({
    children,
    gravity = 'head',
    showLogin,
    showHeader = true,
    expandHorizontally = false,
    width,
}: PropsWithChildren<{
    showHeader?: boolean
    showLogin?: boolean
    gravity?: 'head' | 'center'
    expandHorizontally?: boolean
    width?: string
}>) {
    return (
        <Root>
            <Box
                /* Use CSS grid so vertical alignent of content is w.r.t. the whole
                    page (not 100vh - header's height) */
                display="grid"
            >
                {showHeader && (
                    <Layout>
                        <HuddleHeader showLogin={showLogin} />
                    </Layout>
                )}
                {gravity === 'center' && (
                    <CenteredGravityPageLayout
                        expandHorizontally={expandHorizontally}
                    >
                        {children}
                    </CenteredGravityPageLayout>
                )}
                {gravity === 'head' && (
                    <HeadGravityPageLayout
                        expandHorizontally={expandHorizontally}
                        showHeader={showHeader}
                        width={width}
                    >
                        {children}
                    </HeadGravityPageLayout>
                )}
            </Box>
        </Root>
    )
}

function CenteredGravityPageLayout({
    expandHorizontally,
    children,
}: React.PropsWithChildren<{
    expandHorizontally: Boolean
}>) {
    return (
        <Layout
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            width="fill"
            padding="space20"
            __cssOverrides={{
                margin: '0 auto',
                maxWidth: expandHorizontally ? '100vw' : '488px',
                minHeight: '100vh',
            }}
        >
            {children}
        </Layout>
    )
}

function HeadGravityPageLayout({
    showHeader,
    expandHorizontally,
    children,
    width,
}: React.PropsWithChildren<{
    showHeader?: boolean
    expandHorizontally: boolean
    width?: string
}>) {
    return (
        <Layout
            alignItems="center"
            display="flex"
            flexDirection="column"
            padding="space20"
            __cssOverrides={{
                margin: '0 auto',
                marginTop: showHeader ? '154px' : '64px',
                maxWidth: expandHorizontally ? '100vw' : '488px',
                width,
            }}
        >
            {children}
        </Layout>
    )
}

function Layout({
    children,
    __cssOverrides,
    ...boxProps
}: React.PropsWithChildren<BoxProps>) {
    return (
        <Box
            __cssOverrides={{
                gridRow: 1,
                gridColumn: 1,
                ...__cssOverrides,
            }}
            {...boxProps}
        >
            {children}
        </Box>
    )
}
