import type { PropsWithChildren } from 'react'

export default function Root({ children }: PropsWithChildren<{}>) {
    return (
        <div
            className="root"
            style={{
                overflowY: 'auto',
                height: '100%',
                width: '100%',
            }}
        >
            {children}
        </div>
    )
}
