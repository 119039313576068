import { pickBy } from 'lodash'

import { FlowType } from '@teamflow/types'

import { ElectronService } from '../../../verse/services/ElectronService'

type Params = {
    invitation?: string
    inviteCode?: string
    flowType?: FlowType
    referrer?: string
    redirect?: string
    profilePictureUrl?: string
}

export async function initiateGoogleLogin({
    invitation,
    inviteCode,
    flowType,
    referrer,
    redirect,
    profilePictureUrl,
}: Params) {
    const invitationSuffix = invitation ? `&invitation=${invitation}` : ''

    if (ElectronService.available) {
        const { idToken, accessToken, domain } =
            await ElectronService.waitForGoogleSSO('', flowType, {
                invitation,
                inviteCode,
                referrer,
                profilePictureUrl,
            })

        const data = pickBy(
            {
                // this is the form the api needs
                id_token: idToken,
                accessToken,
                domain,
            },
            (value) => value !== undefined
        ) as Record<string, any>

        const query = new URLSearchParams(data).toString()

        window.location.replace(`/api/auth/google/token?${query}`)
    } else if (inviteCode) {
        window.location.replace(
            `/api/auth/google?inviteCode=${inviteCode}&flow=${flowType}&profilePictureUrl=${profilePictureUrl}&referrer=${referrer}${invitationSuffix}${
                redirect ? `&redirect=${redirect}` : ''
            }`
        )
    } else {
        window.location.replace(
            `/api/auth/google?flow=${flowType}&profilePictureUrl=${profilePictureUrl}&referrer=${referrer}${invitationSuffix}${
                redirect ? `&redirect=${redirect}` : ''
            }`
        )
    }
}
