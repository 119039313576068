import { useState, useRef } from 'react'

import { PasswordStrength } from '@teamflow/lib'
import type { GlobalUser } from '@teamflow/store'

export function useAVSettingsState(isGuest = false, user: GlobalUser | null) {
    const [userEmail, setUserEmail] = useState(user?.emailAddress || '')
    const [userEmailInvalid, setUserEmailInvalid] = useState(false)
    const [userEmailTaken, setUserEmailTaken] = useState(false)
    const [userEmailGeneric, setUserEmailGeneric] = useState(false)
    const [userName, setUserName] = useState(user?.fullName || '')
    const [firstName, setFirstName] = useState(user?.firstName || '')
    const [lastName, setLastName] = useState(user?.lastName || '')
    const [password, setPassword] = useState('')
    const [passwordMinCharacters, setPasswordMinCharacters] = useState(false)
    const [passwordWeak, setPasswordWeak] = useState(false)
    const [passwordStrength, setPasswordStrength] =
        useState<PasswordStrength>(-1)
    const [userPhone, setUserPhone] = useState('')
    const [userPhoneInvalid, setUserPhoneInvalid] = useState(false)

    const [errorMessage, setErrorMessage] = useState('')
    const [dataTestId, setDataTestId] = useState('')
    const [accessDenied, setAccessDenied] = useState(false)

    const isValid = !!(isGuest
        ? userName
        : firstName && lastName && userEmail && password)

    const state = {
        userEmail,
        setUserEmail,
        userEmailInvalid,
        setUserEmailInvalid,
        userEmailTaken,
        setUserEmailTaken,
        userEmailGeneric,
        setUserEmailGeneric,
        userName,
        setUserName,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        password,
        setPassword,
        passwordMinCharacters,
        setPasswordMinCharacters,
        passwordWeak,
        setPasswordWeak,
        passwordStrength,
        setPasswordStrength,
        errorMessage,
        setErrorMessage,
        accessDenied,
        setAccessDenied,
        isValid,
        userPhone,
        setUserPhone,
        userPhoneInvalid,
        setUserPhoneInvalid,
        dataTestId,
        setDataTestId,
    }

    // Return a stable object ref so deps are not over-rendered
    const stateStableRef = useRef(state)
    Object.assign(stateStableRef.current, state)

    return stateStableRef.current
}
