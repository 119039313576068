import { observer } from 'mobx-react'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import {
    formatPhoneNumberIntl,
    isPossiblePhoneNumber,
} from 'react-phone-number-input'

import {
    AVSettingsInterstitialProps,
    loadUserOrgs,
    useFeatureFlag,
} from '@teamflow/bootstrap'
import { api } from '@teamflow/client-api'
import { Box, Stack } from '@teamflow/design'
import { getPasswordStrength, isPasswordWeak, LogManager } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import {
    ApiErrorCode,
    Feature,
    FlowType,
    JoinAccess,
    JoinGuestResult,
    KeyboardShortcut,
} from '@teamflow/types'

import { MIN_PASS_LEN } from '../../../../constants'
import {
    AV_SETUP_JOIN,
    BACKEND_GUEST_CREATED,
    eventWithFlow,
    Flow as AnalyticsFlow,
    Signup,
    track,
} from '../../../../helpers/analytics'
import { isGenericEmail, isValidEmail } from '../../../../helpers/email'
import { useKeyBinding } from '../../../../hooks'
import { putGuestUser } from '../../../../requests/putGuestUser'

import { MenuDivider } from '../../../common/MenuDivider'

import { initiateGoogleLogin } from '../../shared/initiateGoogleLogin'

import { useAVSettingsState } from '../hooks/useAVSettingsState'

import { AVSetupHeading } from './AVSetupHeading'
import { AVSetupJoinButton } from './AVSetupJoinButton'
import { GuestSignupDetails } from './GuestSignupDetails'
import { UserSignupDetails } from './UserSignupDetails'

export interface UserDetailsProps extends AVSettingsInterstitialProps {
    setAccess: (result: { denied: boolean; message: string }) => void
    isLoading?: boolean
}

export const UserDetails = observer(
    ({
        children,
        orgName,
        inviteCode,
        invitation,
        orgSlug = '',
        roomSlug,
        onSubmit,
        signup,
        referrer,
        setAccess,
        checkDomain,
        flowType,
        source = AnalyticsFlow.None,
        isLoading = false,
    }: PropsWithChildren<UserDetailsProps>) => {
        const isGuest = signup === 'guest'
        const isMember = signup === 'member'

        const user = rootStore.users.globalUser
        const { currentOrgId } = user || {}

        // Phone number is only required if you are the org owner
        const isOrgCreator =
            flowType === FlowType.CreateOrg ||
            flowType === FlowType.NewTeam ||
            flowType === FlowType.NewOrg ||
            flowType === FlowType.CreateSalesfloor ||
            (!isGuest && !inviteCode && !orgName)
        const { enabled: requestPhoneNumber } = useFeatureFlag({
            flag: Feature.RequestPhoneNumber,
        })
        const phoneRequired = !!requestPhoneNumber && isOrgCreator

        const localState = useAVSettingsState(isGuest, user)
        const [hubspotCookie, setHubspotCookie] = useState('')

        useEffect(() => {
            const cookies = document.cookie.split(';')

            if (cookies.length > 0) {
                const hs_cookie = cookies.find((cookie) =>
                    cookie.includes('hubspotutk')
                )
                if (hs_cookie) {
                    const [, hubspot_cookie] = hs_cookie.split('=')
                    setHubspotCookie(hubspot_cookie)
                }
            }
        }, [])

        useEffect(() => {
            setAccess({
                denied: localState.accessDenied,
                message: localState.errorMessage,
            })
        }, [setAccess, localState.accessDenied, localState.errorMessage])

        const handleGoogleLoginClick = useCallback(async () => {
            const redirect = window.location.pathname + window.location.search

            track(eventWithFlow(Signup.GoogleSSO, source))

            await initiateGoogleLogin({
                flowType,
                invitation,
                inviteCode,
                referrer,
                redirect,
            })
        }, [flowType, invitation, inviteCode, referrer, source])

        const handleUserNameChange = useCallback(
            (value: string) => {
                localState.setUserName(value.trim())
                localState.setErrorMessage('')
            },
            [localState]
        )

        const handleUserFirstNameChange = useCallback(
            (value: string) => {
                localState.setFirstName(value.trim())
                localState.setErrorMessage('')
            },
            [localState]
        )

        const handleUserLastNameChange = useCallback(
            (value: string) => {
                localState.setLastName(value.trim())
                localState.setErrorMessage('')
            },
            [localState]
        )

        const handleUserEmailChange = useCallback(
            (value: string) => {
                localState.setUserEmailInvalid(false)
                localState.setUserEmailTaken(false)
                localState.setUserEmailGeneric(false)
                localState.setErrorMessage('')
                localState.setUserEmail(value)
            },
            [localState]
        )

        const handleUserPasswordChange = useCallback(
            (value: string) => {
                localState.setPasswordMinCharacters(false)
                localState.setPasswordWeak(false)
                localState.setErrorMessage('')
                localState.setPassword(value)
                localState.setPasswordStrength(getPasswordStrength(value))
            },
            [localState]
        )

        const handleUserPhoneChange = useCallback(
            (value: string) => {
                localState.setUserPhoneInvalid(false)
                localState.setErrorMessage('')
                localState.setUserPhone(value)
            },
            [localState]
        )

        const joinGuest = useCallback(async () => {
            const nameSpaceIndex = localState.userName.indexOf(' ')
            const nameSplitIndex =
                nameSpaceIndex >= 0 ? nameSpaceIndex : undefined
            const [firstName, lastName] = [
                localState.userName?.slice(0, nameSplitIndex),
                nameSplitIndex !== undefined
                    ? localState.userName?.slice(nameSplitIndex + 1)
                    : '',
            ]
            if (localState.userEmail && !isValidEmail(localState.userEmail)) {
                localState.setUserEmailInvalid(true)
                localState.setErrorMessage('Email is invalid')
                return
            }

            const [error, data] = await putGuestUser({
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                checkDomain: !!checkDomain,
                marketingEmail: localState.userEmail,
                firstName,
                lastName,
                inviteCode,
                orgSlug,
                roomSlug,
                invitation,
            })

            if (error?.code === ApiErrorCode.InvalidData) {
                localState.setErrorMessage(error.message ?? 'Missing name')
                return
            }

            if (error) {
                localState.setErrorMessage(error.message ?? 'Please try again')
                return
            }

            if (data?.result === JoinGuestResult.Allowed && data.user) {
                rootStore.users.updateLocalUser(data.user)
                rootStore.users.updateLocalFloorAccess(
                    Boolean(data.floorAccess)
                )

                if (currentOrgId !== data.user.currentOrgId) {
                    await loadUserOrgs()
                }

                track(BACKEND_GUEST_CREATED)
                track(AV_SETUP_JOIN)

                onSubmit?.(data.knock ? JoinAccess.Knock : JoinAccess.Granted)
            } else if (data?.result === JoinGuestResult.Signup) {
                onSubmit?.(JoinAccess.Signup, localState.userEmail)
            } else if (data?.result === JoinGuestResult.Denied) {
                onSubmit?.(JoinAccess.Denied)
            }
        }, [
            onSubmit,
            localState,
            inviteCode,
            orgSlug,
            roomSlug,
            invitation,
            currentOrgId,
            checkDomain,
        ])

        const joinMember = useCallback(async () => {
            const isGenericEmailDomain = await isGenericEmail(
                localState.userEmail
            )
            if (!isValidEmail(localState.userEmail)) {
                localState.setUserEmailInvalid(true)
                localState.setErrorMessage('Email is invalid')
            } else if (isGenericEmailDomain) {
                localState.setUserEmailGeneric(true)
                localState.setErrorMessage('Email is not a work email')
            } else if (localState.password.length < MIN_PASS_LEN) {
                localState.setPasswordMinCharacters(true)
                localState.setErrorMessage('Password too short')
            } else if (isPasswordWeak(localState.password)) {
                localState.setPasswordWeak(true)
                localState.setErrorMessage('Password is not strong enough')
                localState.setDataTestId('tf.signup.user-form.password-is-weak')
            } else if (
                phoneRequired &&
                !isPossiblePhoneNumber(localState.userPhone)
            ) {
                localState.setUserPhoneInvalid(true)
                localState.setErrorMessage('Invalid phone number')
            } else {
                const { data, error } = await api.user.create({
                    email: localState.userEmail,
                    password: localState.password,
                    firstName: localState.firstName,
                    lastName: localState.lastName,
                    phone: requestPhoneNumber
                        ? formatPhoneNumberIntl(localState.userPhone)
                        : undefined,
                    inviteCode,
                    invitation,
                    isOrgCreator,
                    hubspotCookie,
                    utm: rootStore.marketing.utm,
                })

                if (error) {
                    if (error.code === ApiErrorCode.ObjectAlreadyExists) {
                        localState.setUserEmailTaken(true)
                    }
                    localState.setErrorMessage(
                        error.message || 'Please try again'
                    )
                    LogManager.ui.error(
                        `AVSettingsInterstitial > UserDetails: api error: ${
                            error.message || 'unknown error'
                        }`
                    )
                    return
                }

                if (data) {
                    track(eventWithFlow(Signup.EmailAndPassword, source))

                    if (data.user) {
                        rootStore.users.updateGlobalUser(data.user)
                    }

                    if (data.userOrg) {
                        rootStore.users.updateLocalUser(data.userOrg)
                    }
                }

                onSubmit?.()
            }
        }, [
            localState,
            phoneRequired,
            requestPhoneNumber,
            inviteCode,
            invitation,
            isOrgCreator,
            hubspotCookie,
            onSubmit,
            source,
        ])

        const handleJoinClick = useCallback(async () => {
            if (isGuest) {
                await joinGuest()
            } else if (isMember) {
                await joinMember()
            } else {
                onSubmit?.()
            }
        }, [isGuest, isMember, joinGuest, joinMember, onSubmit])

        useKeyBinding(
            KeyboardShortcut.CustomizePlace, // enter key
            handleJoinClick,
            signup === 'none'
        )

        return (
            <>
                <Stack space="space12">
                    <AVSetupHeading
                        isSalesfloor={flowType === FlowType.CreateSalesfloor}
                        isGeneric={signup === 'none'}
                        isGuest={isGuest}
                        orgName={orgName}
                        inviteCode={inviteCode}
                    />
                    {isGuest && (
                        <GuestSignupDetails
                            userName={localState.userName}
                            userEmail={localState.userEmail}
                            onUserNameChange={handleUserNameChange}
                            onUserEmailChange={handleUserEmailChange}
                            userEmailInvalid={localState.userEmailInvalid}
                        />
                    )}
                    {isMember && (
                        <UserSignupDetails
                            userFirstName={localState.firstName}
                            userLastName={localState.lastName}
                            userEmail={localState.userEmail}
                            requestPhoneNumber={requestPhoneNumber ?? false}
                            userEmailTaken={localState.userEmailTaken}
                            userEmailInvalid={localState.userEmailInvalid}
                            userEmailGeneric={localState.userEmailGeneric}
                            passwordMinCharacters={
                                localState.passwordMinCharacters
                            }
                            passwordStrength={localState.passwordStrength}
                            userPhone={localState.userPhone}
                            userPhoneInvalid={localState.userPhoneInvalid}
                            userPhoneRequired={phoneRequired}
                            onUserFirstNameChange={handleUserFirstNameChange}
                            onUserLastNameChange={handleUserLastNameChange}
                            onUserEmailChange={handleUserEmailChange}
                            onUserPhoneChange={handleUserPhoneChange}
                            onUserPasswordChange={handleUserPasswordChange}
                            onGoogleLoginClick={handleGoogleLoginClick}
                        />
                    )}
                    {(isGuest || isMember) && !!children && <MenuDivider />}
                </Stack>
                {children || <Box paddingTop="space16" />}
                <AVSetupJoinButton
                    isLoading={isLoading}
                    isGeneric={signup === 'none'}
                    isGuest={!!isGuest}
                    isDisabled={(isGuest || isMember) && !localState.isValid}
                    errorMessage={localState.errorMessage}
                    dataTestId={localState.dataTestId}
                    showError={
                        !!localState.errorMessage &&
                        !localState.userEmailInvalid &&
                        !localState.userEmailTaken &&
                        !localState.userPhoneInvalid &&
                        !localState.passwordMinCharacters
                    }
                    onJoinClick={handleJoinClick}
                />
            </>
        )
    }
)
