import { useEffect } from 'react'

import { useStableEventCallback } from '@teamflow/lib'

import { Flow, Events, rejectFlow, trackFlow } from '../helpers/userFlow'

export function useTrackFlow(
    flow: Flow,
    event: Events,
    properties?: { [key: string]: unknown }
) {
    const doTrack = useStableEventCallback(
        () => trackFlow(flow, event, properties),
        [event, flow, properties]
    )
    useEffect(() => {
        doTrack()
    }, [doTrack])
}

export function useRejectFlow(
    flow: Flow,
    event: Events,
    properties?: { [key: string]: unknown }
) {
    const doReject = useStableEventCallback(
        () => rejectFlow(flow, event, properties),
        [event, flow, properties]
    )
    useEffect(() => {
        doReject()
    }, [doReject])
}

export { Flow, Events }
